<template>
  <div class="tracking-layout">
    <b-navbar
      class="border-bottom"
      toggleable="sm"
      type="white"
      variant="white"
    >
      <div class="container">
        <div class="w-100 d-md-flex align-items-center justify-content-between">
          <div class="d-md-flex align-items-center text-center">
            <b-navbar-brand :to="{ name: 'home' }">
              <img class="logo-image" src="@/assets/img/logo.png" alt="Zubut" />
            </b-navbar-brand>

            <div class="ml-4 font-weight-semi-bold">
              Seguimiento de servicios
            </div>
          </div>

          <router-link to="/" class="d-none d-md-block font-weight-semi-bold">
            Envía con zubut
          </router-link>
        </div>
      </div>
    </b-navbar>
    <div class="content">
      <router-view @getId="handleId" />
    </div>

    <footer class="py-4">
      <div class="container">
        <b-row>
          <b-col md="5">
            <div class="explore-zubut d-md-block mb-5">
              <a
                class="custommer-support-badge rounded-pill tracking-whatsapp"
                :href="wapLink"
              >
                <div class="p-2 pl-4">
                  Atención al cliente
                </div>
                <div class="pr-4">
                  <font-awesome-icon icon="whatsapp" class="tl-icon" />
                </div>
              </a>
              <div>
                <a href="https://www.zubut.com" target="_blank">
                  <img
                    class="w-100 mt-3 rounded"
                    src="@/assets/img/explore-zubut-banner-short.png"
                    alt="Explora zubut"
                  />
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <a href="https://zubut.com/aviso-de-privacidad" class="text-dark">
                Aviso de privacidad
              </a>
              <a
                href="https://zubut.com/terminos-y-condiciones"
                to="/"
                class="ml-3 text-dark"
              >
                Términos y condiciones
              </a>
            </div>
            <div class="text-secondary">
              © 2021 Zubut
            </div>
          </b-col>
        </b-row>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "TrackingLayout",

  data() {
    return {
      textMessage:
        "Hola, quisiera obtener mas información acerca de mi paquete con numero de guía: ",
      id: "",
      wapNumber: "5213319309267"
    };
  },

  computed: {
    wapLink() {
      return `https://wa.me/${this.wapNumber}?text=${encodeURIComponent(
        this.textMessage.concat(this.id)
      )}`;
    }
  },

  methods: {
    handleId(id) {
      this.id = id;
    }
  }
};
</script>

<style lang="scss" scoped>
.tracking-layout {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-image {
  width: 6em;
}

.content {
  min-height: calc(100vh - 280px);
}

.custommer-support-badge {
  background-color: $emerald;
  text-decoration: none;
  color: $white;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tl-icon {
  font-size: 2rem;
}

.tracking-whatsapp {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .logo-image {
    width: 8em;
  }

  .tracking-whatsapp {
    width: 75%;
  }
}

@media (min-width: 769px) {
  .explore-zubut {
    position: relative;

    button {
      position: absolute;
      right: 1em;
      top: calc(50% - 8px);
      border-radius: 4px;
      font-weight: 700;
    }
  }
}
</style>
